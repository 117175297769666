import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loader from "../Component/Loader/Loader";

// Function to simulate a delay
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const timer = 2.2 * 1000
// Lazy loading with delay
const lazyWithDelay = (importFunc, delayMs) => {
  return lazy(() =>
    delay(delayMs).then(() => importFunc())
  );
};

// Lazy loading of components with 5 seconds delay
const HomeLandingList = lazyWithDelay(() => import("../HomeList/HomeLandingList"), timer);
const RoomSuit = lazyWithDelay(() => import("../Pages/Rooms&Suit/RoomSuit"), timer);
const Gallery = lazyWithDelay(() => import("../Pages/Gallery/Gallery"), timer);
const CafeeKothi = lazyWithDelay(() => import("../Pages/CafeeKothi/CafeeKothi"), timer);
const Press = lazyWithDelay(() => import("../Pages/Press/Press"), timer);
const HotelPolicies = lazyWithDelay(() => import("../Pages/HotelPolicies/HotelPolicies"), timer);
const Contact = lazyWithDelay(() => import("../Pages/Contact/Contact"), timer);
const ArtKothiPage = lazy(() => import("../Pages/ArtKothiPage/ArtKothiPage")); // No delay for main page
const RoomAminities = lazyWithDelay(() => import("../Pages/Rooms&Suit/RoomAminities"), timer);
const RoomDetails = lazyWithDelay(() => import("../Pages/Rooms&Suit/RoomDetails"), timer);
const RoomDetails2 = lazyWithDelay(() => import("../Pages/Rooms&Suit/RoomDetails2"), timer);
const RoomDetails3 = lazyWithDelay(() => import("../Pages/Rooms&Suit/RoomDetails3"), timer);
const RoomDetails4 = lazyWithDelay(() => import("../Pages/Rooms&Suit/RoomDetails4"), timer);
const RoomDetails5 = lazyWithDelay(() => import("../Pages/Rooms&Suit/RoomDetails5"), timer);
const RoomDetails6 = lazyWithDelay(() => import("../Pages/Rooms&Suit/RoomDetails6"), timer);
const RoomDetails7 = lazyWithDelay(() => import("../Pages/Rooms&Suit/RoomDetails7"), timer);
const RoomDetails8 = lazyWithDelay(() => import("../Pages/Rooms&Suit/RoomDetails8"), timer);
const RoomVilla = lazyWithDelay(() => import("../Pages/Rooms&Suit/RoomDetailsVilla"), timer);

const Leisure = lazyWithDelay(() => import("../Pages/Leisure/Leisure"), timer);

function RoutingPage() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<ArtKothiPage />} />
          <Route path="/RoomSuit" element={<RoomSuit />} />
          <Route path="/Dining" element={<Gallery />} />
          <Route path="/cafekothi" element={<CafeeKothi />} />
          <Route path="/Exclusivity&Events" element={<Press />} />
          <Route path="/hotelpolicies" element={<HotelPolicies />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/homepage" element={<HomeLandingList />} />
          <Route path="/roomanimities" element={<RoomAminities />} />
          <Route path="/Leisure" element={<Leisure />} />
          <Route path="/room-no-1" element={<RoomDetails />} />
          <Route path="/room-no-2" element={<RoomDetails2 />} />
          <Route path="/room-no-3" element={<RoomDetails3 />} />
          <Route path="/room-no-4" element={<RoomDetails4 />} />
          <Route path="/room-no-5" element={<RoomDetails5 />} />
          <Route path="/room-no-6" element={<RoomDetails6 />} />
          <Route path="/room-no-7" element={<RoomDetails7 />} />
          <Route path="/room-no-8" element={<RoomDetails8 />} />
          <Route path="/entire-villa" element={<RoomVilla />} />
        
        </Routes>
      </Suspense>
    </Router>
  );
}

export default RoutingPage;